// Generated by Framer (2c4b293)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["cI1hBIwZD"];

const serializationHash = "framer-0xpFz"

const variantClassNames = {cI1hBIwZD: "framer-v-124eo04"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({bGCOLOR, height, id, label, width, ...props}) => { return {...props, HR6VvY__G: label ?? props.HR6VvY__G ?? "SECTION NAME", SJkB94Ng6: bGCOLOR ?? props.SJkB94Ng6 ?? "var(--token-afc428eb-83f4-4256-aa82-c07d2f223ccd, rgb(245, 245, 245))"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;bGCOLOR?: string;label?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, SJkB94Ng6, HR6VvY__G, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "cI1hBIwZD", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-124eo04", className, classNames)} data-framer-name={"Default"} layoutDependency={layoutDependency} layoutId={"cI1hBIwZD"} ref={ref ?? ref1} style={{backgroundColor: SJkB94Ng6, borderBottomLeftRadius: 50, borderBottomRightRadius: 50, borderTopLeftRadius: 50, borderTopRightRadius: 50, ...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "SW50ZXItU2VtaUJvbGQ=", "--framer-font-family": "\"Inter-SemiBold\", \"Inter\", \"Inter Placeholder\", sans-serif", "--framer-font-size": "12px", "--framer-font-weight": "600", "--framer-letter-spacing": "0.05em", "--framer-line-height": "24px", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-r6o4lv, rgb(82, 82, 82))"}}>SECTION NAME</motion.p></React.Fragment>} className={"framer-yavtkn"} data-framer-name={"Subheading"} fonts={["Inter-SemiBold"]} layoutDependency={layoutDependency} layoutId={"lxwikFSdL"} style={{"--extracted-r6o4lv": "rgb(82, 82, 82)", "--framer-paragraph-spacing": "16px"}} text={HR6VvY__G} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-0xpFz.framer-un5ebu, .framer-0xpFz .framer-un5ebu { display: block; }", ".framer-0xpFz.framer-124eo04 { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 2px 12px 1px 12px; position: relative; width: min-content; will-change: var(--framer-will-change-override, transform); }", ".framer-0xpFz .framer-yavtkn { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-0xpFz.framer-124eo04 { gap: 0px; } .framer-0xpFz.framer-124eo04 > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-0xpFz.framer-124eo04 > :first-child { margin-top: 0px; } .framer-0xpFz.framer-124eo04 > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 27
 * @framerIntrinsicWidth 124
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"SJkB94Ng6":"bGCOLOR","HR6VvY__G":"label"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerDT9isorhv: React.ComponentType<Props> = withCSS(Component, css, "framer-0xpFz") as typeof Component;
export default FramerDT9isorhv;

FramerDT9isorhv.displayName = "Section-Badge";

FramerDT9isorhv.defaultProps = {height: 27, width: 124};

addPropertyControls(FramerDT9isorhv, {SJkB94Ng6: {defaultValue: "var(--token-afc428eb-83f4-4256-aa82-c07d2f223ccd, rgb(245, 245, 245)) /* {\"name\":\"Neutral-100\"} */", title: "BG COLOR", type: ControlType.Color}, HR6VvY__G: {defaultValue: "SECTION NAME", displayTextArea: false, title: "Label", type: ControlType.String}} as any)

addFonts(FramerDT9isorhv, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/hyOgCu0Xnghbimh0pE8QTvtt2AU.woff2", weight: "600"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/NeGmSOXrPBfEFIy5YZeHq17LEDA.woff2", weight: "600"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/oYaAX5himiTPYuN8vLWnqBbfD2s.woff2", weight: "600"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/lEJLP4R0yuCaMCjSXYHtJw72M.woff2", weight: "600"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/cRJyLNuTJR5jbyKzGi33wU9cqIQ.woff2", weight: "600"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/1ZFS7N918ojhhd0nQWdj3jz4w.woff2", weight: "600"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/A0Wcc7NgXMjUuFdquHDrIZpzZw0.woff2", weight: "600"}]}], {supportsExplicitInterCodegen: true})